/* eslint-disable no-console */
import {
  getApp,
  getApps,
  FirebaseApp,
  initializeApp,
  FirebaseOptions,
} from 'firebase/app';
import { getAuth, Auth, connectAuthEmulator } from 'firebase/auth';
import {
  getFirestore,
  Firestore,
  connectFirestoreEmulator,
} from 'firebase/firestore';
import {
  getFunctions,
  Functions,
  connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions';
import {
  getStorage,
  FirebaseStorage,
  connectStorageEmulator,
} from 'firebase/storage';
import { FirebaseFunctionNames, Regions } from 'shared';

export class CustomFirebaseSwr {
  public db: Firestore;
  public auth: Auth;
  public functions: Functions;
  public storage: FirebaseStorage;
  public app: FirebaseApp;

  constructor(config: FirebaseOptions) {
    this.app = !getApps().length ? initializeApp(config) : getApp();
    this.db = getFirestore();
    this.auth = getAuth();
    this.functions = getFunctions(this.app, Regions.eu);
    if (process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATOR) {
      this.storage = getStorage(this.app, 'gs://demo-local.appspot.com');
      connectFirestoreEmulator(this.db, 'localhost', 8080);
      connectAuthEmulator(this.auth, 'http://localhost:9099');
      connectFunctionsEmulator(this.functions, 'localhost', 5001);
      connectStorageEmulator(this.storage, 'localhost', 9199);
      httpsCallable(
        this.functions,
        FirebaseFunctionNames.INIT_EMPLOYEE_ACCOUNT
      )()
        .then((r) => console.log({ r }))
        .catch((e) => console.log({ e }));
      httpsCallable(this.functions, FirebaseFunctionNames.INIT_TEMPLATES)()
        .then((r) => console.log({ r }))
        .catch((e) => console.log({ e }));
    } else {
      this.storage = getStorage();
    }
  }
}

const config = process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATOR
  ? { projectId: 'demo-local', apiKey: 'dummy-api-key' }
  : {
      apiKey: process.env.NEXT_PUBLIC_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_APP_ID,
    };
const firebaseSwr = new CustomFirebaseSwr(config);

export default firebaseSwr;
