import {
  InputTypeEnum,
  LayoutEnum,
  SidebarStepModel,
  TemplateModel,
} from 'shared';
import { Store } from 'store/store';
import firebaseSwr from 'utils/firebase-swr';
import {
  logEmployeeEditedTemplate,
  setTemplate,
} from 'utils/template/template';
import { GetState, SetState } from 'zustand';

export type OnboardingSlice = {
  template: null | TemplateModel;
  setTemplate: (template: TemplateModel) => void;
  currentSectionPointer: number;
  scrollIsLocked: boolean;
  setTemplateValue: (
    sectionPointer: number,
    stepPointer: number,
    key: string,
    value: string
  ) => Promise<void>;
  setTemplateSidevarValue: (
    sectionPointer: number,
    stepPointer: number,
    value: string
  ) => Promise<void>;
  setCompleteStep: (
    sectionPointer: number,
    stepPointer: number,
    completed?: boolean
  ) => void;
  toggleSteps: (enabledSteps: string[], disabledSteps: string[]) => void;
  lockScroll: (lock: boolean) => void;
  isInLockedState: boolean;
  setIsInLockedState: (isInLockedState: boolean) => void;
  employeeImpersonatingUserId: string | undefined;
  setEmployeeImpersonatingUserId: (id: string | undefined) => void;
  isEmployee: boolean;
  setIsEmployee: (isEmployee: boolean) => void;
};

export const onboardingInitialState = {
  currentSectionPointer: -1,
  template: null,
  scrollIsLocked: false,
  isInLockedState: false,
  employeeImpersonatingUserId: undefined,
  isEmployee: false,
} as OnboardingSlice;

export const createOnboardingSlice = (
  set: SetState<Store>,
  get: GetState<Store>
) =>
  ({
    ...onboardingInitialState,
    setTemplate: (template) => set({ template }),
    setTemplateValue: async (
      sectionPointer: number,
      stepPointer: number,
      key: string,
      value: string
    ) => {
      const isInLockedState = get().isInLockedState;
      const activetemplate = get().template;

      // SPECIAL CASE FOR LAST STEP WHICH IS THE ADDITIONAL INFORMATION
      const isLastSection =
        sectionPointer + 1 === activetemplate.sections.length;
      if (isInLockedState && !isLastSection) return;

      const section = activetemplate.sections[sectionPointer];

      if (!section) {
        throw 'There is no section for this pointer';
      }

      const step = section.steps[stepPointer];
      if (!step) {
        throw 'There is no step for this pointer';
      }

      const isSidebarValue = step.layout === LayoutEnum.SIDEBAR;
      const inputs = 'inputs' in step ? step.inputs : [];

      if (!isSidebarValue && !inputs.find((input) => input.name === key)) {
        throw 'There is no input with this name key';
      }

      inputs.forEach((input) => {
        if (input.name === key) {
          if (
            input.type === InputTypeEnum.FILE_UPLOAD ||
            input.type === InputTypeEnum.PHONE ||
            input.type === InputTypeEnum.ADD_LIST
          ) {
            // TODO Fix any below
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            input.multiValues = value as any;
          } else {
            input.value = value;
          }
        }
      });

      await setTemplate(
        activetemplate,
        get().employeeImpersonatingUserId ?? firebaseSwr.auth.currentUser.uid
      );

      if (get().employeeImpersonatingUserId) {
        logEmployeeEditedTemplate(
          step.title,
          get().employeeImpersonatingUserId,
          firebaseSwr.auth.currentUser.uid
        );
      }

      set(() => ({
        template: { ...activetemplate },
      }));
    },
    setTemplateSidevarValue: async (
      sectionPointer: number,
      stepPointer: number,
      value: string
    ) => {
      const isInLockedState = get().isInLockedState;
      if (isInLockedState) return;

      const activetemplate = get().template;
      const section = activetemplate.sections[sectionPointer];

      if (!section) {
        throw 'There is no section for this pointer';
      }

      const step = section.steps[stepPointer] as SidebarStepModel;
      if (!step) {
        throw 'There is no step for this pointer';
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      step.sidebarValues = value as any;

      await setTemplate(
        activetemplate,
        get().employeeImpersonatingUserId ?? firebaseSwr.auth.currentUser.uid
      );

      if (get().employeeImpersonatingUserId) {
        logEmployeeEditedTemplate(
          step.title,
          get().employeeImpersonatingUserId,
          firebaseSwr.auth.currentUser.uid
        );
      }

      set(() => ({
        template: { ...activetemplate },
      }));
    },
    setCompleteStep: (
      sectionPointer: number,
      stepPointer: number,
      completed?: boolean
    ) =>
      set((state) => {
        if (state.isInLockedState) return;
        const activetemplate = state.template;
        const section = activetemplate.sections[sectionPointer];
        if (!section) {
          throw 'There is no section for this pointer';
        }

        if (!section.steps[stepPointer]) {
          throw 'There is no step for this pointer';
        }

        section.steps[stepPointer].completed = completed ?? true;

        setTemplate(
          activetemplate,
          state.employeeImpersonatingUserId ?? firebaseSwr.auth.currentUser.uid
        );
        return {
          template: { ...activetemplate },
        };
      }),
    toggleSteps: (enabledSteps: string[], disabledSteps: string[]) => {
      set((state) => {
        if (state.isInLockedState) return;
        const template = state.template;
        template.sections.forEach((section) => {
          section.steps.map((step) => {
            if (enabledSteps.includes(step.id)) {
              step.enabled = true;
            }

            if (disabledSteps.includes(step.id)) {
              step.enabled = false;
              step.completed = false;

              // Reset values
              const inputs = 'inputs' in step ? step.inputs : [];
              inputs.forEach((input) => {
                input.value = '';

                if ('multiValues' in input && input.multiValues) {
                  input.multiValues = [];
                }
              });
            }

            return step;
          });
        });
        setTemplate(
          template,
          state.employeeImpersonatingUserId ?? firebaseSwr.auth.currentUser.uid
        );
        return {
          template: { ...template },
        };
      });
    },
    lockScroll: (lock: boolean) => {
      set(() => {
        return {
          scrollIsLocked: lock,
        };
      });
    },
    setIsInLockedState: (isInLockedState) => set({ isInLockedState }),
    setEmployeeImpersonatingUserId: (
      employeeImpersonatingUserId: string | undefined
    ) => set({ employeeImpersonatingUserId }),
    setIsEmployee: (isEmployee: boolean) => set({ isEmployee }),
  } as OnboardingSlice);
