export const theme = {
  colors: {
    brand: {
      green: {
        '500': '#BEFF6C',
        '600': '#A9FF3D',
      },
      slime_green: {
        '500': '#D6F60E',
        '600': '#C4E208',
      },
    },
    product: {
      red: {
        '500': '#FF5F43',
        '400': '#FBCEC6',
      },
      yellow: {
        '500': '#FFB903',
        '400': '#FFD66C',
      },
      purple: {
        '500': '#7055F8',
        '400': '#C4BAF7',
      },
      blue: {
        '500': '#88DBFF',
        '400': '#CCF0FF',
      },
    },
    utility: {
      black: {
        '500': '#121212',
        '400': '#282828',
        '300': '#3D3D3D',
        '200': '#868686',
      },
      white: {
        '500': '#FFFFFF',
        '400': '#DDDDDD',
        '300': '#F4F4F4',
        '200': '#FAFAFA',
      },
    },
  },
  columns: { lg: 12, md: 12, sm: 6 },
  breakPoints: { lg: 1200, md: 996, sm: 768 },
  spacing: {
    xs: 4,
    sm: 8,
    md: 12,
    lg: 16,
    xl: 20,
    xxl: 30,
  },
};
