import { User } from 'firebase/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store/store';
import firebaseSwr from 'utils/firebase-swr';

interface ContextProps {
  token: string;
  uid: string;
  updateUserData: () => void;
}

export const AuthContext = React.createContext({
  token: null,
  uid: null,
} as ContextProps);

export default function AuthProvider({ children }) {
  const [authLoaded, setAuthLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const setIsEmployee = useStore.useSetIsEmployee();

  const updateUserData = async () => {
    const authUser = firebaseSwr.auth.currentUser;
    const userData = {
      // TODO Fix any below
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      token: (authUser as any).xa,
      uid: authUser.uid,
    };
    setUser(userData);
  };

  const authStateChangeHandler = useCallback(
    (authUser: User | null) => {
      if (authUser) {
        updateUserData();
        authUser
          .getIdTokenResult()
          .then((result) => setIsEmployee(!!result.claims.employee));
      } else {
        setUser(null);
        setIsEmployee(false);
      }
      setAuthLoaded(true);
    },
    [setIsEmployee]
  );

  useEffect(() => {
    const unsubscribe = firebaseSwr.auth.onAuthStateChanged(
      authStateChangeHandler
    );

    return () => {
      unsubscribe();
    };
  }, [authStateChangeHandler]);

  return (
    <AuthContext.Provider
      value={{
        updateUserData,
        ...user,
      }}
    >
      {authLoaded ? children : null}
    </AuthContext.Provider>
  );
}
