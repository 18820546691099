import {
  ActivityAction,
  ActivityLog,
  FirestoreCollections,
  TemplateModel,
} from 'shared';
import { addDoc, collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import firebaseSwr from 'utils/firebase-swr';

export const setTemplate = async (template: TemplateModel, userId: string) => {
  const templateRef = doc(
    firebaseSwr.db,
    FirestoreCollections.userTemplates,
    userId
  );
  await setDoc(templateRef, template);
};

export const logEmployeeEditedTemplate = (
  stepName: string,
  userId: string,
  employeeId: string
) => {
  const log: ActivityLog = {
    action: ActivityAction.CHANGE_ONBOARDING_DATA,
    time: Timestamp.now(),
    customerId: userId,
    employeeId: employeeId,
    stepTitle: stepName,
  };
  addDoc(collection(firebaseSwr.db, FirestoreCollections.activityLog), log);
};
