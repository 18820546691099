import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import {
  OnboardingSlice,
  createOnboardingSlice,
  onboardingInitialState,
} from 'store/slices/onboardingSlice';
import {
  GeneralSlice,
  createGeneralSlice,
  generalInitialState,
} from 'store/slices/generalSlice';
export type Store = OnboardingSlice & GeneralSlice;

export const initialState = {
  ...onboardingInitialState,
  ...generalInitialState,
};

export const useStoreBase = create<Store>(
  persist(
    (set, get) => ({
      ...createOnboardingSlice(set, get),
      ...createGeneralSlice(set),
    }),
    {
      name: 'token-storage',
      getStorage: () => localStorage,
      whitelist: ['isEmployee', 'employeeImpersonatingUserId'],
    }
  )
);

export const useStore = createSelectorHooks(useStoreBase);
