import { Store } from 'store/store';
import { SetState } from 'zustand';

export type GeneralSlice = {
  isUpdatingTemplate: boolean;
  setIsUpdatingTemplate: (loading: boolean) => void;
};

export const generalInitialState = {
  isUpdatingTemplate: false,
} as GeneralSlice;

export const createGeneralSlice = (set: SetState<Store>) =>
  ({
    ...generalInitialState,
    setIsUpdatingTemplate: (loading: boolean) => {
      set(() => ({
        isUpdatingTemplate: loading,
      }));
    },
  } as GeneralSlice);
