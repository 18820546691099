import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fast.fonts.net/lt/1.css?apiType=css&c=c48b9b03-b96f-44b1-8bf6-81601142b9a0&fontids=5664085,5664089,5664093,5664098,5664103,5664107,5664111,5664149');
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/5664085/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2')
        format('woff2'),
      url('/fonts/5664085/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff')
        format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/5664089/5e4f385b-17ff-4d27-a63a-9ee28546c9a8.woff2')
        format('woff2'),
      url('/fonts/5664089/116cde47-4a07-44a5-9fac-cbdcc1f14f79.woff')
        format('woff');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/5664093/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2')
        format('woff2'),
      url('/fonts/5664093/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff')
        format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/5664098/4bd56f95-e7ab-4a32-91fd-b8704cbd38bc.woff2')
        format('woff2'),
      url('/fonts/5664098/4fe1c328-1f21-434a-8f0d-5e0cf6c70dfb.woff')
        format('woff');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/5664103/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2')
        format('woff2'),
      url('/fonts/5664103/7802e576-2ffa-4f22-a409-534355fbea79.woff')
        format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/5664107/de68be2a-5d0e-4b8d-b3eb-940f75503e2a.woff2')
        format('woff2'),
      url('/fonts/5664107/31029e78-79a0-4940-b82d-2e3c238e1355.woff')
        format('woff');
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/5664111/13ab58b4-b5ba-4c95-afde-ab2608fbbbd9.woff2')
        format('woff2'),
      url('/fonts/5664111/5018b5b5-c821-4653-bc74-d0b11d735f1a.woff')
        format('woff');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/5664150/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2')
        format('woff2'),
      url('/fonts/5664150/7b415a05-784a-4a4c-8c94-67e9288312f5.woff')
        format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'NeuePixelGrotesk';
    src: url('/fonts/NeuePixelGrotesk_Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  :root {
    --size-grid-gutter: 16px;
    --size-grid-padding: 40px;
    --sidebar-width-collapsed: 95px;
    --sidebar-width-expanded: 250px;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', sans-serif;
  }

  body {
    margin: 0;
    min-height: 100vh;
    background-color: #E5E5E5;
    color: #121212;

    @supports (-webkit-touch-callout: none) {
     min-height: -webkit-fill-available;
   }
  }

  [tabindex],
  a,
  button,
  input,
  label,
  select,
  textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-size: 100%;
    font-weight: 400;
    line-height: inherit;
    margin: 0;
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: currentColor;
    text-decoration: none;
    text-decoration-skip-ink: auto;
  }

  button {
    background-color: transparent;
    border: none;
    color: currentColor;
    cursor: pointer;
    padding: 0;
    outline: none;
    user-select: none;
  }

  /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  #__next {
    min-height: 100vh;

    @supports (-webkit-touch-callout: none) {
     min-height: -webkit-fill-available;
   }
  }

  .highcharts-credits {
    display: none;
  }

  .pushpin-marker {
    animation: rotate 2s linear infinite;
    transform-origin: center center;
  }

  .react-tiny-popover-container {
    z-index: 1000;
  }


  @keyframes loader-animation {
    0% {
      top: 9px;
      height: 2px;
    }
    50%, 100% {
      top: 5px;
      height: 10px;
    }
  }

  @keyframes loader-animation-large {
    0% {
      top: 7px;
      height: 4px;
    }
    50%, 100% {
      top: 0px;
      height: 20px;
    }
  }






  //////////////////////////////////////////////////
  REACT DATE
  //////////////////////////////////////////////////

  .CalendarDay__default {
    border: none;
  }

  /*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #BEFF6C;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #c1c1c1;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}
  
.react-switch-bg {
  box-shadow: 0 0 0 1px #000;
}

`;

export default GlobalStyle;
