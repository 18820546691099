import { theme } from 'app/global-theme';
import GlobalDateStyle from 'app/global-date-style';
import GlobalStyle from 'app/global-style';
import { ThemeProvider } from 'styled-components';
import { FuegoProvider } from 'swr-firestore-v9';
import firebaseSwr from 'utils/firebase-swr';
import AuthProvider from 'providers/authProvider';

function MyApp({ Component, pageProps, err }) {
  return (
    <FuegoProvider fuego={firebaseSwr}>
      <AuthProvider>
        <GlobalDateStyle />
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          {/* Passing err as a workaround for https://github.com/vercel/next.js/issues/8592*/}
          <Component {...pageProps} err={err} />
        </ThemeProvider>
      </AuthProvider>
    </FuegoProvider>
  );
}

export default MyApp;
